<template>
  <div>
    <div class="table_content">
      <!-- 表格内容 -->
      <commonTitle></commonTitle>

      <div class="search">
        <el-form ref="form" :model="form" label-width="80px" :inline="true">
          <el-form-item label="单元名称:">
            <el-input v-model="form.unitName" size="mini" placeholder="请输入小区名称"></el-input>
          </el-form-item>

          <button @click.prevent="common()" style="cursor: pointer;">搜索</button>
          <button @click.prevent="exportExcel()" style="cursor: pointer;">导出</button>
        </el-form>
      </div>
      <!--表格-->
      <el-card>
        <el-table
          :data="tableData"
          v-loading="loading"
          element-loading-text="拼命加载中"
          style="width:100%"
          :stripe="true"
        >
          <el-table-column property="unitName" label="单元名称" show-overflow-tooltip></el-table-column>
          <el-table-column property="assessmentSum" label="应缴金额" show-overflow-tooltip></el-table-column>
          <el-table-column property="amountPaySum" label="已缴金额" show-overflow-tooltip></el-table-column>
          <el-table-column property="standardAmountSum" label="标准金额" show-overflow-tooltip></el-table-column>
          <el-table-column property="houseacctBalSum" label="账户余额" show-overflow-tooltip></el-table-column>
        </el-table>
        <el-row>
          <el-pagination
            background
            style="margin-top: 50px"
            layout="prev, pager, next"
            :page-size="15"
            @current-change="handleCurrentChange"
            :total="totalNum"
          ></el-pagination>
          <el-button @click="back" class="btn" size="small">上一步</el-button>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import commonTitle from "../table/commonTitle";
var towerId = JSON.parse(localStorage.getItem("towerData")).towerId;
export default {
  name: "reportUnit",
  components: {
    commonTitle
  },
  data() {
    return {
      form: {
        unitName: ""
      },
      tableData: [],
      multipleSelection: [],
      totalNum: 15,
      url: this.$Config.base_server,
      loading: false,
      isShow: "",
      data: {
        access_token: localStorage.getItem("token"),
        limit: 15,
        towerId: JSON.parse(localStorage.getItem("towerData")).towerId
      }
    };
  },
  components: {
    commonTitle
  },
  mounted() {
    this.common();
  },
  methods: {
    common(currentPage = 0) {
      const that = this;
      that.loading = true;

      that.data.page = currentPage;
      //搜索框的值
      that.data.unitName = that.form.unitName;
      $.ajax({
        url: this.url + "/api-public/report/unitRepairReport",
        data: that.data,
        contentType: "application/json",
        type: "get",
        success: function(res) {
          that.loading = false;
          if (res.code == 0) {
            that.totalNum = res.count;
            that.tableData = res.data;
          }
        },
        error: function(res) {
          that.loading = false;
        }
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleCurrentChange(val) {
      //当前页数改变
      this.common(val);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    back() {
      //返回按钮
      this.$router.push("/reportTower");
    },
    exportExcel() {
      var unitNameVal = this.form.unitName;
      var url =
        this.url +
        "/api-public/report/unitRepairReportExport?access_token=" +
        localStorage.getItem("token") +
        "&unitName=" +
        unitNameVal +
        "&towerId=" +
        towerId;
      location.href = url;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/css/table/first_table.css";

.el-button {
  padding: 5px 5px;
}
.btn {
  float: right;
  transform: translateY(-34px);
}
</style>
